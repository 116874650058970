// src/components/ShareButtons.tsx
import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

type ShareButtonsProps = {
  url: string;
  title: string;
  description?: string;
  hashtags?: string[];
};

const ShareButtons: React.FC<ShareButtonsProps> = ({
  url,
  title,
  description,
  hashtags,
}) => {
  return (
    <div className="share-buttons flex space-x-4">
      {/* Facebook シェアボタン */}
      <FacebookShareButton
        url={url}
        hashtag={hashtags ? `#${hashtags.join(" #")}` : ""}
        className="hover:scale-110 transition-transform"
      >
        <FacebookIcon size={40} round />
      </FacebookShareButton>

      {/* Twitter シェアボタン */}
      <TwitterShareButton
        url={url}
        title={`${title} ${description}`}
        hashtags={hashtags}
        className="hover:scale-110 transition-transform"
      >
        <TwitterIcon size={40} round />
      </TwitterShareButton>

      {/* Line シェアボタン */}
      <LineShareButton
        url={url}
        title={title}
        className="hover:scale-110 transition-transform"
      >
        <LineIcon size={40} round />
      </LineShareButton>

      {/* LinkedIn シェアボタン */}
      <LinkedinShareButton
        url={url}
        title={title}
        summary={description}
        className="hover:scale-110 transition-transform"
      >
        <LinkedinIcon size={40} round />
      </LinkedinShareButton>
    </div>
  );
};

export default ShareButtons;
