import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import log from "loglevel";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { FaArrowUp } from "react-icons/fa";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Login from "./components/Auth/Login";
import PasswordReset from "./components/Auth/PasswordReset";
import Register from "./components/Auth/Register";
import Navbar from "./components/Navbar";
import ShareButtons from "./components/ShareButtons";
import SubscriptionForm from "./components/SubscriptionForm";
import Summary from "./components/Summary/Summary";
import TitleAndHeaderGenerator from "./components/TitleAndHeaderGenerator/TitleAndHeaderGenerator";
import Transcription from "./components/Transcription/Transcription";
import { Button } from "./components/ui/button";
import { deleteAccount, logout } from "./services/authService";
import { auth } from "./services/firebaseConfig";

type UsageData = {
  totalMinutesUsed: number;
  maxMinutes: number;
  titlesAndHeadingsUsage: number;
  maxTitlesAndHeadingsUsage: number;
  summarizeUsage: number;
  maxSummaryUsage: number;
  resetDate: string | null;
  plan: string;
  pendingPlan: string | null;
};

// 環境変数からStripeの公開鍵を読み込む
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
);

// 環境に応じたログレベルの設定
if (process.env.NODE_ENV === "development") {
  log.setLevel("debug");
} else {
  log.setLevel("error");
}

const App: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeService, setActiveService] = useState<string>("transcription");
  const [transcriptionData, setTranscriptionData] = useState<any>(null);
  const [rewrittenText, setRewrittenText] = useState<string | null>(null);
  const [audioFileUrl, setAudioFileUrl] = useState<string | null>(null);
  const [titleHeaderData, setTitleHeaderData] = useState<any>(null);
  const [summaryData, setSummaryData] = useState<any>(null);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [usageData, setUsageData] = useState<UsageData>({
    totalMinutesUsed: 0,
    maxMinutes: 30,
    titlesAndHeadingsUsage: 0,
    maxTitlesAndHeadingsUsage: 3,
    summarizeUsage: 0,
    maxSummaryUsage: 3,
    resetDate: null,
    plan: "free",
    pendingPlan: null,
  });
  const [isScrollTopVisible, setIsScrollTopVisible] = useState<boolean>(false);

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  // Firebase Auth 言語設定
  useEffect(() => {
    const auth = getAuth();
    const currentLanguage = i18n.language || "en";
    auth.languageCode = currentLanguage; // Firebase Auth の言語を設定
    log.debug(`Firebase Auth language set to: ${currentLanguage}`);
  }, [i18n.language]); // 言語が変更されるたびに再実行

  const fetchUsageData = useCallback(async (uid: string) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL + "/api/user-usage";

    try {
      log.debug("使用回数データを取得中...");
      const response = await fetch(`${backendUrl}?uid=${uid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (!response.ok) {
        throw new Error(`サーバーエラー: ${response.status}`);
      }
      const data = await response.json();
      log.debug("サーバーからのレスポンス: ", data);

      setUsageData({
        totalMinutesUsed: data.totalMinutesUsed || 0,
        maxMinutes: data.maxMinutes || 30,
        titlesAndHeadingsUsage: data.titlesAndHeadingsUsage || 0,
        maxTitlesAndHeadingsUsage: data.maxTitlesAndHeadingsUsage || 3,
        summarizeUsage: data.summarizeUsage || 0,
        maxSummaryUsage: data.maxSummaryUsage || 3,
        resetDate: data.resetDate || null,
        plan: data.plan || "free",
        pendingPlan: data.pendingPlan || null,
      });
    } catch (error) {
      log.error("使用回数データの取得エラー:", error);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        log.debug("Authenticated user via Firebase:", user);
        fetchUsageData(user.uid);
      } else {
        setCurrentUser(null);
        log.debug("No user is logged in via Firebase.");
      }
    });

    return () => unsubscribe();
  }, [fetchUsageData]);

  const handleLogout = async () => {
    try {
      await logout();
      setCurrentUser(null);
      log.debug("User logged out successfully.");
      navigate("/login");
    } catch (error) {
      log.error("Logout failed:", error);
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm(t("messages.delete_account"))) {
      try {
        await deleteAccount();
        setCurrentUser(null);
        log.debug("User account deleted successfully.");
        navigate("/login");
      } catch (error) {
        log.error("Account deletion failed:", error);
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrollTopVisible(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t("meta.title")}</title>
        <meta name="description" content={t("meta.description")} />
        <meta property="og:title" content={t("meta.og_title")} />
        <meta property="og:description" content={t("meta.og_description")} />
        <meta property="og:image" content="/images/interview-ai-ogp.png" />
      </Helmet>

      <Elements stripe={stripePromise}>
        <div className="app-container">
          <Navbar
            currentUser={currentUser}
            onLogout={handleLogout}
            onDeleteAccount={handleDeleteAccount}
          />

          <div className="container mx-auto mt-20 pt-5 px-4">
            {!location.pathname.includes("/login") &&
              !location.pathname.includes("/upgrade-plan") && (
                <div className="flex justify-center mb-12 mt-4">
                  <div className="flex space-x-4">
                    <Link to="/transcription">
                      <Button
                        variant={
                          activeService === "transcription"
                            ? "primary"
                            : "outline"
                        }
                        className="w-auto"
                        onClick={() => setActiveService("transcription")}
                      >
                        {t("features.transcription")}
                      </Button>
                    </Link>

                    <Link to="/summary">
                      <Button
                        variant={
                          activeService === "summary" ? "primary" : "outline"
                        }
                        className="w-auto"
                        onClick={() => setActiveService("summary")}
                      >
                        {t("features.summary")}
                      </Button>
                    </Link>

                    <Link to="/title-header-generator">
                      <Button
                        variant={
                          activeService === "title-header-generator"
                            ? "primary"
                            : "outline"
                        }
                        className="w-auto"
                        onClick={() =>
                          setActiveService("title-header-generator")
                        }
                      >
                        {t("features.title_header")}
                      </Button>
                    </Link>
                  </div>
                </div>
              )}

            <Routes>
              <Route path="/" element={<Navigate to="/transcription" />} />
              <Route
                path="/transcription"
                element={
                  <Transcription
                    transcriptionData={transcriptionData}
                    setTranscriptionData={setTranscriptionData}
                    rewrittenText={rewrittenText}
                    setRewrittenText={setRewrittenText}
                    audioFileUrl={audioFileUrl}
                    setAudioFileUrl={setAudioFileUrl}
                    fetchUsageData={fetchUsageData}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                  />
                }
              />
              <Route
                path="/title-header-generator"
                element={
                  <TitleAndHeaderGenerator
                    titleHeaderData={titleHeaderData}
                    setTitleHeaderData={setTitleHeaderData}
                    fetchUsageData={fetchUsageData}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                  />
                }
              />
              <Route
                path="/summary"
                element={
                  <Summary
                    summaryData={summaryData}
                    setSummaryData={setSummaryData}
                  />
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route
                path="/upgrade-plan"
                element={
                  currentUser ? (
                    <SubscriptionForm
                      fetchUsageData={fetchUsageData}
                      usageData={usageData}
                    />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
            </Routes>

            <div className="mt-12">
              <ShareButtons
                url="https://www.interview-ai.site/"
                title={t("share.title")}
                description={t("share.description")}
                hashtags={
                  t("share.hashtags", { returnObjects: true }) as string[]
                }
              />
            </div>
          </div>

          {isScrollTopVisible && (
            <button
              onClick={scrollToTop}
              className="fixed bottom-4 right-4 bg-blue-500 text-white p-2 rounded-full shadow-lg hover:bg-blue-600 transition"
              aria-label="Scroll to top"
            >
              <FaArrowUp size={24} />
            </button>
          )}
        </div>
      </Elements>
    </HelmetProvider>
  );
};

export default App;
