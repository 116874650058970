import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Button } from "./ui/button";

type NavbarProps = {
  currentUser: any;
  onLogout: () => void;
  onDeleteAccount: () => void;
};

const Navbar: React.FC<NavbarProps> = ({
  currentUser,
  onLogout,
  onDeleteAccount,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleMenu = () => setIsOpen(!isOpen);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav className="p-4 fixed top-0 w-full z-10 bg-white">
      <div className="container mx-auto flex justify-between items-center">
        {/* ロゴ */}
        <Link to="/" className="flex items-center text-brand font-bold text-xl">
          <img
            src="/images/interview-ai-logo.png"
            alt="Logo"
            className="h-6 w-6 mr-2"
          />
          {t("menu.app_name")}
        </Link>

        {/* ハンバーガーメニュー（スマホ用） */}
        <div className="md:hidden">
          <button onClick={toggleMenu}>
            {isOpen ? (
              <AiOutlineClose className="h-6 w-6 text-gray-800" />
            ) : (
              <AiOutlineMenu className="h-6 w-6 text-gray-800" />
            )}
          </button>
        </div>

        {/* ナビゲーションリンク */}
        <div
          className={`${
            isOpen ? "block" : "hidden"
          } md:flex flex-col md:flex-row items-center md:items-center absolute md:static top-16 left-0 w-full md:w-auto bg-white md:bg-transparent`}
        >
          <Link
            to="/upgrade-plan"
            className="text-gray-800 hover:text-blue-600 mx-2 py-2 md:py-0"
          >
            {t("menu.plan")}
          </Link>
          <a
            href="https://www.interview-ai.site/how-to-use-interviewai/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-800 hover:text-blue-600 mx-2 py-2 md:py-0"
          >
            {t("menu.how_to_use")}
          </a>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeGpCKSrHcT6HVzQNOL27-KPPli0LxYCXaphMv6QE8I-rA9KA/viewform"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-800 hover:text-blue-600 mx-2 py-2 md:py-0"
          >
            {t("menu.contact")}
          </a>

          {currentUser ? (
            <>
              <Button
                variant="ghost"
                onClick={onLogout}
                className="text-gray-800 hover:bg-gray-600 hover:text-white mx-2 py-2 md:py-0"
              >
                {t("menu.logout")}
              </Button>
              <Button
                variant="ghost"
                onClick={onDeleteAccount}
                className="text-gray-800 hover:bg-gray-600 hover:text-white mx-2 py-2 md:py-0"
              >
                {t("menu.delete_account")}
              </Button>
            </>
          ) : (
            <Link to="/login">
              <Button
                variant="ghost"
                className="text-gray-800 hover:bg-gray-600 hover:text-white mx-2 py-2 md:py-0"
              >
                {t("menu.login")}
              </Button>
            </Link>
          )}

          {/* 言語選択ドロップダウンの追加 */}
          <div className="ml-4">
            <select
              value={i18n.language} // デフォルトで現在の言語を選択
              onChange={(e) => changeLanguage(e.target.value)}
              className="border border-gray-300 rounded p-1"
            >
              <option value="en">English</option>
              <option value="ja">日本語</option>
              <option value="zh">中文</option>
              <option value="de">Deutsch</option>
              <option value="fr">Français</option>
              <option value="it">Italiano</option>
              <option value="th">ไทย</option>
              <option value="nl">Nederlands</option>
              <option value="id">Bahasa Indonesia</option>
              <option value="el">Ελληνικά</option>
              <option value="ko">한국어</option>
              <option value="hi">हिन्दी</option>
              <option value="sv">Svenska</option>
              <option value="pl">Polski</option>
              <option value="es">Español</option>
              <option value="ru">Русский</option>
              <option value="pt">Português</option>
              <option value="tr">Türkçe</option>
              <option value="vi">Tiếng Việt</option>
              <option value="ta">தமிழ்</option>
              <option value="fa">فارسی</option>
              <option value="tl">Filipino</option>
            </select>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
