// src/services/authService.ts
import axios from "axios";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "../services/firebaseConfig";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// 新規登録
export const register = async (email: string, password: string) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    // メール認証を送信
    await sendEmailVerification(user);
    // ユーザー情報をバックエンドに送信してMongoDBに保存
    await axios.post(`${backendUrl}/api/auth/users`, {
      uid: user.uid,
      email: user.email,
    });

    return user;
  } catch (error) {
    throw error;
  }
};

// ログイン
export const login = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    // メール認証が完了していない場合はエラーをスロー
    if (!user.emailVerified) {
      throw new Error(
        "メール認証が完了していません。メールを確認してください。"
      );
    }

    localStorage.setItem("user", JSON.stringify(user));
    return user;
  } catch (error) {
    throw error;
  }
};

// ログイン状態の取得
export const getCurrentUser = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

// パスワードリセット
export const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    throw error;
  }
};

// ログアウト
export const logout = async () => {
  try {
    await signOut(auth);
    localStorage.removeItem("user");
  } catch (error) {
    throw error;
  }
};

// 退会
export const deleteAccount = async () => {
  const user = auth.currentUser;

  if (!user) {
    throw new Error("ユーザーがログインしていません");
  }

  try {
    // Firebaseからユーザーを削除
    await user.delete();

    // MongoDBからもユーザー情報を削除
    const response = await axios.delete(
      `${backendUrl}/api/auth/users/${user.uid}`
    );

    // レスポンスを確認
    if (response.status !== 200) {
      throw new Error("MongoDBからのユーザー削除に失敗しました");
    }

    // ローカルストレージのユーザー情報も削除
    localStorage.removeItem("user");
  } catch (error) {
    throw error;
  }
};
