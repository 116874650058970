// src/components/ui/progress.tsx
import React from "react";

type ProgressProps = {
  value: number; // 0から100の値
};

export const Progress: React.FC<ProgressProps> = ({ value }) => {
  const clampedValue = Math.min(Math.max(value, 0), 100);
  let bgColor = "bg-green-500";

  if (clampedValue > 80) {
    bgColor = "bg-red-500";
  } else if (clampedValue > 50) {
    bgColor = "bg-yellow-500";
  }

  return (
    <div className="w-full bg-gray-200 rounded-full h-4">
      <div
        className={`${bgColor} h-4 rounded-full`}
        style={{ width: `${clampedValue}%` }}
      ></div>
    </div>
  );
};
