import React from "react";

type AlertProps = {
  variant?: "success" | "danger" | "warning" | "info" | "brand";
  children?: React.ReactNode;
  dangerouslySetInnerHTML?: { __html: string };
};

const variantStyles: Record<string, string> = {
  success: "bg-green-100 text-green-800 border border-green-300",
  danger: "bg-red-100 text-red-800 border border-red-300",
  warning: "bg-yellow-100 text-yellow-800 border border-yellow-300",
  info: "bg-brand text-white border border-brand",
  brand: "bg-brand-light text-white border border-brand",
};

export const Alert: React.FC<AlertProps> = ({
  variant = "info",
  children,
  dangerouslySetInnerHTML,
}) => {
  return (
    <div
      className={`p-4 mb-4 rounded-md ${variantStyles[variant]}`}
      role="alert"
    >
      {dangerouslySetInnerHTML ? (
        <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
      ) : (
        children
      )}
    </div>
  );
};
