import log from "loglevel";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { auth } from "../../services/firebaseConfig";
import { Alert } from "../ui/alert";
import { Button } from "../ui/button";
import { Progress } from "../ui/progress";
import "./Transcription.css";

type TranscriptionProps = {
  transcriptionData: any;
  setTranscriptionData: React.Dispatch<any>;
  rewrittenText: string | null;
  setRewrittenText: React.Dispatch<React.SetStateAction<string | null>>;
  audioFileUrl: string | null;
  setAudioFileUrl: React.Dispatch<React.SetStateAction<string | null>>;
  fetchUsageData: (uid: string) => Promise<void>;
  isUploading: boolean;
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
};

// エラーメッセージの型
type ErrorMessage = {
  message: string;
  link?: JSX.Element | null;
};

if (process.env.NODE_ENV === "development") {
  log.setLevel("debug");
} else {
  log.setLevel("error");
}

const Transcription: React.FC<TranscriptionProps> = ({
  transcriptionData,
  setTranscriptionData,
  rewrittenText,
  setRewrittenText,
  audioFileUrl,
  setAudioFileUrl,
  fetchUsageData,
  isUploading,
  setIsUploading,
}) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [interviewer, setInterviewer] = useState<string>("");
  const [interviewee, setInterviewee] = useState<string>("");
  const [tone, setTone] = useState<string>("");
  const [interviewExample, setInterviewExample] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState("meeting");

  const [transcriptionProgress, setTranscriptionProgress] = useState<number>(0);
  const [correctionProgress, setCorrectionProgress] = useState<number>(0);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [usageError, setUsageError] = useState<ErrorMessage | null>(null);

  const MAX_FILE_SIZE = 20 * 1024 * 1024;
  const navigate = useNavigate();

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const rewrittenTextRef = useRef<HTMLTextAreaElement>(null);

  const [transcriptionTime, setTranscriptionTime] = useState<string | null>(
    null
  );
  const [correctionTime, setCorrectionTime] = useState<string | null>(null);

  const uploadProgressInterval = useRef<NodeJS.Timeout | null>(null);

  const handleWebSocketMessages = useCallback(
    async (data: any) => {
      log.debug(t("messages.processCompleted"), data);

      switch (data.status) {
        case "Transcription started":
        case "Transcription in progress":
        case "Transcription complete":
          setTranscriptionProgress(data.transcriptionProgress);
          break;
        case "Correction started":
        case "Correction in progress":
        case "Correction complete":
          setCorrectionProgress(data.correctionProgress);
          break;
        case "File uploaded":
          setAudioFileUrl(data.audioFileUrl);
          break;
        default:
          log.warn(t("messages.errorData"), data.status);
      }

      if (data.status === "Transcription complete") {
        setTranscriptionData(data.transcription);
        if (data.transcriptionTime) {
          setTranscriptionTime(
            `${parseFloat(data.transcriptionTime).toFixed(1)} ${t(
              "transcription.result.seconds"
            )}`
          );
          log.debug(
            `${t("transcription.result.processingTime")}: ${
              data.transcriptionTime
            }`
          );
        }

        if (auth.currentUser) {
          await fetchUsageData(auth.currentUser.uid);
        }
      }

      if (data.status === "Correction complete") {
        setRewrittenText(data.rewrittenText);
        if (data.correctionTime) {
          setCorrectionTime(
            `${parseFloat(data.correctionTime).toFixed(1)} ${t(
              "transcription.result.seconds"
            )}`
          );
          log.debug(
            `${t("transcription.result.correctionProcessingTime")}: ${
              data.correctionTime
            }`
          );
        }
        setIsProcessing(false);
        setIsUploading(false);
      }
    },
    [
      setAudioFileUrl,
      setRewrittenText,
      setTranscriptionData,
      fetchUsageData,
      setIsUploading,
      t,
    ]
  );

  useEffect(() => {
    if (isProcessing) {
      const wsUrl =
        process.env.REACT_APP_WEBSOCKET_URL || "ws://localhost:5001";
      const ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        log.debug(t("messages.firebaseUser"));
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleWebSocketMessages(data);
      };

      ws.onclose = () => {
        log.debug("WebSocket connection closed");
      };

      ws.onerror = (error) => {
        log.error(t("messages.errorResponse"), error);
      };

      return () => {
        ws.close();
      };
    }
  }, [isProcessing, handleWebSocketMessages, t]);

  const adjustTextareaHeight = useCallback(
    (textarea: HTMLTextAreaElement | null) => {
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    },
    []
  );

  useEffect(() => {
    log.debug(t("messages.processCompleted"), transcriptionData);
  }, [transcriptionData, t]);

  useEffect(() => {
    if (rewrittenTextRef.current) {
      adjustTextareaHeight(rewrittenTextRef.current);
    }
  }, [rewrittenText, adjustTextareaHeight]);

  useEffect(() => {
    if (rewrittenText) {
      log.debug(t("messages.processCompleted"), rewrittenText);
      adjustTextareaHeight(rewrittenTextRef.current);
    }
  }, [rewrittenText, adjustTextareaHeight, t]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setTranscriptionData(null);
    setRewrittenText(null);
    setAudioFileUrl(null);
    setError(null);
    setUsageError(null);

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError(t("transcription.fileSizeLimit"));
        setSelectedFile(null);
        return;
      }
      setSelectedFile(file);
    }
  };

  const handleSubmit = async () => {
    const backendUrl = `${process.env.REACT_APP_BACKEND_URL}/api/upload`;

    const user = auth.currentUser;
    log.debug(t("messages.firebaseUser"), user);
    if (!user) {
      const userConfirmed = window.confirm(t("messages.loginRequired"));
      if (userConfirmed) {
        navigate("/login");
      }
      return;
    }

    if (!selectedFile) {
      setError(t("messages.fileNotSelected"));
      return;
    }

    if (!selectedModel) {
      setError(t("transcription.selectFileType"));
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("interviewer", interviewer);
    formData.append("interviewee", interviewee);
    formData.append("tone", tone);
    formData.append("interviewExample", interviewExample);
    formData.append("model", selectedModel);
    formData.append("uid", user.uid);

    setLoading(true);
    setError(null);
    setUsageError(null);
    setIsProcessing(true);
    setIsUploading(true);
    setTranscriptionProgress(0);

    const simulateUploadProgress = () => {
      if (uploadProgressInterval.current) {
        clearInterval(uploadProgressInterval.current);
      }
      uploadProgressInterval.current = setInterval(() => {
        setTranscriptionProgress((prev) => {
          if (prev < 5) {
            return Math.min(prev + 0.5, 5);
          } else {
            if (uploadProgressInterval.current) {
              clearInterval(uploadProgressInterval.current);
              uploadProgressInterval.current = null;
            }
            return 5;
          }
        });
      }, 1000);
    };

    try {
      simulateUploadProgress();

      const token = await user.getIdToken();

      const response = await fetch(backendUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        log.error(t("messages.errorData"), errorData);

        const { message, link } = errorData.error || {};
        if (message) {
          setUsageError({
            message: message,
            link: link ? (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline"
              >
                {t("messages.contactSupport")}
              </a>
            ) : null,
          });
        } else {
          setError(`${t("messages.serverError")}: ${response.status}`);
        }

        setIsProcessing(false);
        setIsUploading(false);
        setTranscriptionProgress(0);

        if (uploadProgressInterval.current) {
          clearInterval(uploadProgressInterval.current);
          uploadProgressInterval.current = null;
        }

        return;
      }

      const data = await response.json();
      log.debug(t("messages.processCompleted"), data.message);

      setTranscriptionProgress((prev) => Math.max(prev, 5));

      if (uploadProgressInterval.current) {
        clearInterval(uploadProgressInterval.current);
        uploadProgressInterval.current = null;
      }
    } catch (err: any) {
      log.error(t("messages.errorResponse"), err.message);
      setError(err.message || t("messages.unexpectedError"));
      setIsProcessing(false);
      setIsUploading(false);
      setTranscriptionProgress(0);

      if (uploadProgressInterval.current) {
        clearInterval(uploadProgressInterval.current);
        uploadProgressInterval.current = null;
      }
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    if (rewrittenTextRef.current) {
      navigator.clipboard.writeText(rewrittenTextRef.current.value);
      alert(t("transcription.result.copyCorrectedInterview"));
    }
  };

  return (
    <div className="container mx-auto mt-5 px-4">
      <div className="mb-4 flex flex-col md:flex-row items-start">
        <div className="w-full">
          <input
            type="file"
            id="file-upload"
            onChange={handleFileUpload}
            disabled={isUploading}
            aria-label={t("transcription.selectFile")}
            className="w-full"
          />
          <p className="mt-1 text-xs text-gray-500">
            {t("transcription.fileSizeLimit")}
          </p>
        </div>
        <div className="mt-4 md:mt-0 md:ml-4 flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4 w-full">
          <select
            className="block w-full md:w-auto p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            value={selectedModel}
            onChange={(e) => setSelectedModel(e.target.value)}
            disabled={isUploading}
            aria-label={t("transcription.selectFileType")}
          >
            <option value="meeting">
              {t("transcription.fileTypes.meeting")}
            </option>
            <option value="general">
              {t("transcription.fileTypes.generalConversation")}
            </option>
            <option value="phonecall">
              {t("transcription.fileTypes.phoneCall")}
            </option>
            <option value="voicemail">
              {t("transcription.fileTypes.voicemail")}
            </option>
            <option value="finance">
              {t("transcription.fileTypes.financialReport")}
            </option>
            <option value="conversationalai">
              {t("transcription.fileTypes.aiAssistant")}
            </option>
            <option value="video">
              {t("transcription.fileTypes.videoAudio")}
            </option>
            <option value="medical">
              {t("transcription.fileTypes.medical")}
            </option>
          </select>

          <Button
            variant={isUploading ? "ghost" : "primary"}
            onClick={handleSubmit}
            disabled={isUploading || !selectedFile || loading}
            aria-label={t("transcription.uploadAudio")}
            className="w-full md:w-auto"
          >
            {loading ? (
              <>
                <span
                  className="animate-spin inline-block h-4 w-4 mr-2 border-2 border-white border-t-transparent rounded-full"
                  role="status"
                  aria-hidden="true"
                ></span>
                {t("transcription.status.uploading")}
              </>
            ) : (
              t("transcription.uploadAudio")
            )}
          </Button>
        </div>
      </div>

      {error && <Alert variant="danger">{error}</Alert>}
      {usageError && (
        <Alert variant="warning">
          {usageError.message} {usageError.link}
        </Alert>
      )}

      {isProcessing && transcriptionProgress < 100 && (
        <div className="mb-4">
          <Progress value={transcriptionProgress} />
          <span className="text-sm text-gray-700">
            {Math.floor(transcriptionProgress)}%
          </span>
        </div>
      )}
      {isProcessing &&
        transcriptionProgress === 100 &&
        correctionProgress < 100 && (
          <div className="mb-4">
            <Progress value={correctionProgress} />
            <span className="text-sm text-gray-700">{correctionProgress}%</span>
          </div>
        )}

      {isProcessing && (
        <p className="font-semibold text-blue-600">
          {transcriptionProgress < 5 && t("transcription.status.uploading")}
          {transcriptionProgress >= 5 &&
            transcriptionProgress < 100 &&
            t("transcription.status.transcribing")}
          {transcriptionProgress === 100 &&
            correctionProgress === 0 &&
            t("transcription.status.startingCorrection")}
          {correctionProgress > 0 &&
            correctionProgress < 100 &&
            t("transcription.status.correctingTranscription")}
          {correctionProgress === 100 &&
            t("transcription.status.correctionComplete")}
        </p>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label
              htmlFor="interviewer"
              className="block text-sm font-medium text-gray-700"
            >
              {t("transcription.labels.interviewer")} (
              {t("transcription.labels.optional")})
            </label>
            <input
              type="text"
              id="interviewer"
              value={interviewer}
              onChange={(e) => setInterviewer(e.target.value)}
              placeholder={t("transcription.labels.interviewerNamePlaceholder")}
              disabled={isUploading}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              aria-label={t("transcription.labels.interviewerName")}
            />
          </div>
          <div>
            <label
              htmlFor="interviewee"
              className="block text-sm font-medium text-gray-700"
            >
              {t("transcription.labels.interviewee")} (
              {t("transcription.labels.optional")})
            </label>
            <input
              type="text"
              id="interviewee"
              value={interviewee}
              onChange={(e) => setInterviewee(e.target.value)}
              placeholder={t("transcription.labels.intervieweeNamePlaceholder")}
              disabled={isUploading}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              aria-label={t("transcription.labels.intervieweeName")}
            />
          </div>
          <div>
            <label
              htmlFor="tone"
              className="block text-sm font-medium text-gray-700"
            >
              {t("transcription.labels.selectTone")} (
              {t("transcription.labels.optional")})
            </label>
            <select
              id="tone"
              value={tone}
              onChange={(e) => setTone(e.target.value)}
              disabled={isUploading}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              aria-label={t("transcription.labels.selectTone")}
            >
              <option value="">
                {t("transcription.labels.selectTonePlaceholder")}
              </option>
              <option value="formal">
                {t("transcription.labels.tones.formal")}
              </option>
              <option value="casual">
                {t("transcription.labels.tones.casual")}
              </option>
              <option value="pop">{t("transcription.labels.tones.pop")}</option>
              <option value="serious">
                {t("transcription.labels.tones.serious")}
              </option>
            </select>
          </div>
        </div>

        <div>
          <label
            htmlFor="interviewExample"
            className="block text-sm font-medium text-gray-700"
          >
            {t("transcription.example.interviewExample")} (
            {t("transcription.labels.optional")})
          </label>
          <textarea
            id="interviewExample"
            rows={3}
            placeholder={t("transcription.example.interviewExamplePlaceholder")}
            value={interviewExample}
            onChange={(e) => setInterviewExample(e.target.value)}
            disabled={isUploading}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            aria-label={t("transcription.example.interviewExample")}
          />
          <p className="mt-1 text-sm text-gray-500">
            {t("transcription.example.interviewExampleNote")}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="mt-3">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <h5 className="text-lg font-semibold">
                {t("transcription.result.transcriptionResult")}
              </h5>
              {audioFileUrl && (
                <audio
                  ref={audioRef}
                  controls
                  src={audioFileUrl}
                  className="ml-4 h-8"
                  onError={() => alert(t("transcription.result.audioExpired"))}
                >
                  {t("transcription.result.audioNotSupported")}
                </audio>
              )}
            </div>
            <span className="text-sm text-gray-700">
              {transcriptionData ? transcriptionData.length : 0}{" "}
              {t("transcription.result.characters")}
            </span>
          </div>
          {transcriptionTime && (
            <div className="mt-2">
              <strong>
                {t("transcription.result.processingTime")}: {transcriptionTime}
              </strong>
            </div>
          )}
          <div className="mt-2 transcription-display">
            {transcriptionData ? (
              <p>{transcriptionData}</p>
            ) : (
              <p className="text-gray-500">
                {t("transcription.result.transcriptionResultPlaceholder")}
              </p>
            )}
          </div>
        </div>

        <div className="mt-3">
          <div className="flex justify-between items-center">
            <h5 className="text-lg font-semibold">
              {t("transcription.result.correctedInterview")}
            </h5>
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-700">
                {rewrittenText ? rewrittenText.length : 0}{" "}
                {t("transcription.result.characters")}
              </span>
              <Button
                variant={rewrittenText ? "primary" : "ghost"}
                onClick={copyToClipboard}
                disabled={isUploading || !rewrittenText}
                aria-label={t("transcription.result.copyCorrectedInterview")}
              >
                {t("transcription.result.copy")}
              </Button>
            </div>
          </div>
          {correctionTime && (
            <div className="mt-2">
              <strong>
                {t("transcription.result.correctionProcessingTime")}:{" "}
                {correctionTime}
              </strong>
            </div>
          )}
          <textarea
            className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            rows={10}
            value={rewrittenText || ""}
            onChange={(e) => setRewrittenText(e.target.value)}
            ref={rewrittenTextRef}
            disabled={isUploading}
            aria-label={t("transcription.result.correctedInterview")}
          />
        </div>
      </div>
    </div>
  );
};
export default Transcription;
