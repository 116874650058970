import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { auth } from "../../services/firebaseConfig";
import { Alert } from "../ui/alert";
import { Button } from "../ui/button";
import "./TitleAndHeaderGenerator.css";

type TitleAndHeaderGeneratorProps = {
  titleHeaderData: any;
  setTitleHeaderData: React.Dispatch<any>;
  fetchUsageData: (uid: string) => Promise<void>;
  isUploading: boolean;
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
};

type ErrorMessage = {
  message: string;
  link?: JSX.Element | null;
};

const TitleAndHeaderGenerator: React.FC<TitleAndHeaderGeneratorProps> = ({
  titleHeaderData,
  setTitleHeaderData,
  fetchUsageData,
  isUploading,
  setIsUploading,
}) => {
  const { t } = useTranslation();
  const [originalText, setOriginalText] = useState<string>("");
  const [numHeadings, setNumHeadings] = useState<number>(3);
  const [numTitles, setNumTitles] = useState<number>(3);
  const [titles, setTitles] = useState<string[]>([]);
  const [headings, setHeadings] = useState<string[]>([]);
  const [customPrompt, setCustomPrompt] = useState<string>("");

  const contentRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);

  useEffect(() => {
    if (titleHeaderData) {
      setOriginalText(titleHeaderData.originalText || "");
      setNumHeadings(titleHeaderData.numHeadings || 3);
      setNumTitles(titleHeaderData.numTitles || 3);
      setTitles(titleHeaderData.titles || []);
      setHeadings(titleHeaderData.headings || []);
    }
  }, [titleHeaderData]);

  const handleGenerateTitlesAndHeadings = async () => {
    if (!originalText.trim()) {
      setErrorMessage({ message: t("titleHeader.errors.enterOriginalText") });
      return;
    }

    if (numHeadings <= 0) {
      setErrorMessage({ message: t("titleHeader.errors.minHeadings") });
      return;
    }

    if (numTitles <= 0) {
      setErrorMessage({ message: t("titleHeader.errors.minTitles") });
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      const userConfirmed = window.confirm(
        t("titleHeader.errors.loginRequired")
      );
      if (userConfirmed) {
        navigate("/login");
      }
      return;
    }

    setLoading(true);
    setIsUploading(true);
    setErrorMessage(null);

    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(
        `${backendUrl}/api/generate-titles-and-headings`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: user.uid,
            originalText,
            numHeadingsPerArticle: numHeadings,
            numTitles: numTitles,
            customPrompt,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        const { message, link } = errorData.error;

        setErrorMessage({
          message: message,
          link: link ? (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              {t("titleHeader.errors.contactSupport")}
            </a>
          ) : null,
        });
        return;
      }

      const data = await response.json();
      setTitles(data.titles);
      setHeadings(data.headings);

      setTitleHeaderData({
        originalText,
        numHeadings,
        numTitles,
        titles: data.titles,
        headings: data.headings,
      });

      fetchUsageData(user.uid);
    } catch (error: any) {
      setErrorMessage({
        message: error.message || t("titleHeader.errors.serverError"),
        link: null,
      });
    } finally {
      setLoading(false);
      setIsUploading(false);
    }
  };

  const scrollToHeading = (headingIndex: number) => {
    const content = contentRef.current;
    if (content) {
      const headingElements = content.querySelectorAll(`h4`);
      if (headingElements[headingIndex]) {
        const headingPosition =
          headingElements[headingIndex].getBoundingClientRect().top +
          window.scrollY -
          100;
        window.scrollTo({ top: headingPosition, behavior: "smooth" });
      }
    }
  };

  const insertHeadingsInText = (text: string, headings: string[]) => {
    const paragraphs = text.split("\n").filter(Boolean);
    if (headings.length === 0) {
      return paragraphs
        .map((para, idx) => `<p key=${idx}>${para}</p>`)
        .join("");
    }
    const chunkSize = Math.ceil(paragraphs.length / headings.length);
    let formattedText = "";
    let headingIndex = 0;

    for (let i = 0; i < paragraphs.length; i++) {
      if (i % chunkSize === 0 && headingIndex < headings.length) {
        formattedText += `<h4 class="font-bold text-2xl mt-6">${headings[headingIndex]}</h4>`;
        headingIndex++;
      }
      formattedText += `<p>${paragraphs[i]}</p>`;
    }

    return formattedText;
  };

  const copyToClipboard = () => {
    if (contentRef.current && contentRef.current.innerHTML) {
      const textToCopy = contentRef.current.innerText;
      navigator.clipboard.writeText(textToCopy).then(() => {
        alert(t("titleHeader.actions.copiedToClipboard"));
      });
    }
  };

  return (
    <div className="container mx-auto mt-5 px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="md:col-span-1">
          <h5 className="text-lg font-semibold mb-2">
            {t("titleHeader.labels.originalText")}
          </h5>
          <textarea
            className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            rows={15}
            value={originalText}
            onChange={(e) => setOriginalText(e.target.value)}
            placeholder={t("titleHeader.placeholders.originalText")}
            disabled={isUploading}
          ></textarea>
          <div className="text-right mt-1 text-sm text-gray-600">
            {originalText.length} {t("titleHeader.labels.characters")}
          </div>
        </div>

        <div className="md:col-span-1 mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="numHeadings"
                className="block text-sm font-medium text-gray-700"
              >
                {t("titleHeader.labels.numHeadings")}
              </label>
              <input
                type="number"
                id="numHeadings"
                value={numHeadings}
                onChange={(e) => setNumHeadings(Number(e.target.value))}
                disabled={isUploading}
                min={1}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="numTitles"
                className="block text-sm font-medium text-gray-700"
              >
                {t("titleHeader.labels.numTitles")}
              </label>
              <input
                type="number"
                id="numTitles"
                value={numTitles}
                onChange={(e) => setNumTitles(Number(e.target.value))}
                disabled={isUploading}
                min={1}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="md:col-span-2">
              <label
                htmlFor="customPrompt"
                className="block text-sm font-medium text-gray-700"
              >
                {t("titleHeader.labels.customPrompt")}
              </label>
              <textarea
                id="customPrompt"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                rows={3}
                value={customPrompt}
                onChange={(e) => setCustomPrompt(e.target.value)}
                placeholder={t("titleHeader.placeholders.customPrompt")}
                disabled={isUploading}
              ></textarea>
            </div>
          </div>

          <div className="mt-4">
            <Button
              variant="primary"
              onClick={handleGenerateTitlesAndHeadings}
              disabled={isUploading || loading}
              aria-label={t("titleHeader.actions.generateTitlesAndHeadings")}
            >
              {loading ? (
                <>
                  <span
                    className="animate-spin inline-block h-4 w-4 mr-2 border-2 border-white border-t-transparent rounded-full"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {t("titleHeader.status.generating")}
                </>
              ) : (
                t("titleHeader.actions.generateTitlesAndHeadings")
              )}
            </Button>
          </div>
        </div>
      </div>
      {errorMessage && (
        <div className="mt-4">
          <Alert variant="danger">
            {errorMessage.message} {errorMessage.link}
          </Alert>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-6">
        <div className="md:col-span-3 p-4">
          <h4 className="text-lg font-semibold mb-2">
            {t("titleHeader.labels.titles")}
          </h4>
          <table className="w-full ">
            <tbody>
              {titles.map((title, index) => (
                <tr key={index} className="border-b">
                  <td className="py-2">{title}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <h4 className="text-lg font-semibold mt-4 mb-2">
            {t("titleHeader.labels.headings")}
          </h4>
          <table className="w-full border-collapse">
            <tbody>
              {headings.map((heading, index) => (
                <tr key={index} className="border-b">
                  <td
                    className="py-2 text-blue-600 cursor-pointer hover:underline"
                    onClick={() => scrollToHeading(index)}
                    aria-label={t("titleHeader.actions.scrollToHeading", {
                      index: index + 1,
                    })}
                  >
                    {heading}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="md:col-span-9">
          <div className="flex justify-between items-center">
            <h4 className="text-lg font-semibold">
              {t("titleHeader.labels.output")}
            </h4>
            <Button
              variant={
                contentRef.current && contentRef.current.innerHTML && !loading
                  ? "primary"
                  : "ghost"
              }
              onClick={copyToClipboard}
              disabled={
                loading || !contentRef.current || !contentRef.current.innerHTML
              }
              aria-label={t("titleHeader.actions.copyOutput")}
            >
              {t("titleHeader.actions.copyOutput")}
            </Button>
          </div>
          {titles.length > 0 && <h2 className="mt-3 text-2xl">{titles[0]}</h2>}
          <div
            id="outputContent"
            ref={contentRef}
            dangerouslySetInnerHTML={{
              __html: insertHeadingsInText(originalText, [...headings]),
            }}
            className="mt-2 text-base leading-relaxed"
          />
        </div>
      </div>
    </div>
  );
};

export default TitleAndHeaderGenerator;
