import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { resetPassword } from "../../services/authService";
import { Alert } from "../ui/alert";
import { Button } from "../ui/button";

const PasswordReset: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await resetPassword(email);
      setMessage(t("passwordReset.successMessage"));
      setError(null);
    } catch (err) {
      setError(t("passwordReset.errors.failed"));
      setMessage(null);
    }
  };

  return (
    <div className="flex items-start justify-center min-h-screen px-4">
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-center mb-6">
          {t("passwordReset.title")}
        </h2>
        <form onSubmit={handleResetPassword}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {t("passwordReset.labels.email")}
            </label>
            <input
              type="email"
              id="email"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label={t("passwordReset.labels.email")}
            />
          </div>
          {message && <Alert variant="success">{message}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          <Button type="submit" variant="primary" className="w-full">
            {t("passwordReset.actions.sendResetLink")}
          </Button>
        </form>
        <div className="mt-6 text-center">
          <p className="text-sm text-gray-600">
            {t("passwordReset.login.prompt")}
          </p>
          <Link to="/login">
            <Button variant="outline" className="mt-2 w-full">
              {t("passwordReset.login.action")}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
