import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import ar from "./locales/ar.json"; // アラビア語
import de from "./locales/de.json"; // ドイツ語
import el from "./locales/el.json"; // ギリシャ語
import en from "./locales/en.json"; // 英語
import es from "./locales/es.json"; // スペイン語
import fa from "./locales/fa.json"; // ペルシャ語
import fr from "./locales/fr.json"; // フランス語
import hi from "./locales/hi.json"; // ヒンディー語
import id from "./locales/id.json"; // インドネシア語
import it from "./locales/it.json"; // イタリア語
import ja from "./locales/ja.json"; // 日本語
import ko from "./locales/ko.json"; // 韓国語
import nl from "./locales/nl.json"; // オランダ語
import pl from "./locales/pl.json"; // ポーランド語
import pt from "./locales/pt.json"; // ポルトガル語
import ru from "./locales/ru.json"; // ロシア語
import sv from "./locales/sv.json"; // スウェーデン語
import ta from "./locales/ta.json"; // タミル語
import th from "./locales/th.json"; // タイ語
import tl from "./locales/tl.json"; // フィリピン語
import tr from "./locales/tr.json"; // トルコ語
import uk from "./locales/uk.json"; // ウクライナ語
import vi from "./locales/vi.json"; // ベトナム語
import zh from "./locales/zh.json"; // 中国語

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ar: { translation: ar },
      de: { translation: de },
      el: { translation: el },
      en: { translation: en },
      es: { translation: es },
      fr: { translation: fr },
      hi: { translation: hi },
      id: { translation: id },
      it: { translation: it },
      ja: { translation: ja },
      ko: { translation: ko },
      nl: { translation: nl },
      pl: { translation: pl },
      sv: { translation: sv },
      th: { translation: th },
      uk: { translation: uk },
      zh: { translation: zh },
      ru: { translation: ru }, // ロシア語
      pt: { translation: pt }, // ポルトガル語
      tr: { translation: tr }, // トルコ語
      vi: { translation: vi }, // ベトナム語
      ta: { translation: ta }, // タミル語
      fa: { translation: fa }, // ペルシャ語
      tl: { translation: tl }, // フィリピン語
    },
    fallbackLng: "en", // 言語が見つからない場合のフォールバック言語
    interpolation: { escapeValue: false },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["localStorage", "cookie"],
    },
  });

export default i18n;
