import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../../services/authService";
import { Alert } from "../ui/alert";
import { Button } from "../ui/button";

const Register: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await register(email, password);
      setMessage(t("register.successMessage"));
      setError(null);

      setTimeout(() => {
        navigate("/login");
      }, 3000); // 3秒後にリダイレクト
    } catch (err) {
      setError(t("register.errors.failed"));
      setMessage(null);
    }
  };

  return (
    <div className="flex items-start justify-center min-h-screen px-4">
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-center mb-6">
          {t("register.title")}
        </h2>
        <form onSubmit={handleRegister}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {t("register.labels.email")}
            </label>
            <input
              type="email"
              id="email"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label={t("register.labels.email")}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              {t("register.labels.password")}
            </label>
            <input
              type="password"
              id="password"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              aria-label={t("register.labels.password")}
            />
          </div>
          {message && <Alert variant="success">{message}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          <Button type="submit" variant="primary" className="w-full">
            {t("register.actions.submit")}
          </Button>
        </form>

        <p className="text-xs mt-4">
          {t("register.agreement.text")}
          <a
            href="https://www.interview-ai.site/terms/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600"
          >
            {t("register.agreement.terms")}
          </a>
          {t("register.agreement.and")}
          <a
            href="https://www.interview-ai.site/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600"
          >
            {t("register.agreement.privacyPolicy")}
          </a>
          {t("register.agreement.agree")}
        </p>

        <div className="mt-6 text-center">
          <p className="text-sm text-gray-600">{t("register.login.prompt")}</p>
          <Link to="/login">
            <Button variant="outline" className="mt-2 w-full">
              {t("register.login.action")}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Register;
