import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { auth } from "../../services/firebaseConfig";
import { Alert } from "../ui/alert";
import { Button } from "../ui/button";
import { Progress } from "../ui/progress";

type SummaryProps = {
  summaryData: any;
  setSummaryData: React.Dispatch<any>;
};

type ErrorMessage = {
  message: string;
  link?: JSX.Element | null;
};

const Summary: React.FC<SummaryProps> = ({ summaryData, setSummaryData }) => {
  const { t, i18n } = useTranslation();
  const [originalText, setOriginalText] = useState<string>(
    summaryData?.originalText || ""
  );
  const [desiredLength, setDesiredLength] = useState<number>(
    summaryData?.desiredLength || 3000
  );
  const [requiredPhrases, setRequiredPhrases] = useState<string[]>(
    summaryData?.requiredPhrases || ["", ""]
  );
  const [summary, setSummary] = useState<string>(summaryData?.summary || "");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const summaryRef = useRef<HTMLTextAreaElement>(null);
  const [isCopying, setIsCopying] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    setOriginalText(summaryData?.originalText || "");
    setDesiredLength(summaryData?.desiredLength || 3000);
    setRequiredPhrases(summaryData?.requiredPhrases || ["", ""]);
    setSummary(summaryData?.summary || "");
  }, [summaryData]);

  const handleSubmit = async () => {
    const filledPhrases = requiredPhrases.filter(
      (phrase: string) => phrase.trim() !== ""
    );

    if (desiredLength > originalText.length) {
      setErrorMessage({ message: t("summary.messages.desiredLengthError") });
      setIsLoading(false);
      return;
    }

    if (filledPhrases.length < 2) {
      setErrorMessage({ message: t("summary.messages.requiredPhrasesError") });
      setIsLoading(false);
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      const userConfirmed = window.confirm(t("summary.messages.loginRequired"));
      if (userConfirmed) {
        navigate("/login");
      }
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    setErrorMessage(null);
    setSummary("");

    let simulatedProgress = 0;
    const progressInterval = setInterval(() => {
      if (simulatedProgress < 98) {
        simulatedProgress += 1;
        setProgress(simulatedProgress);
      }
    }, 1000);

    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(`${backendUrl}/api/summary/summarize`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: user.uid,
          text: originalText,
          requiredPhrases: filledPhrases,
          desiredLength,
          language: i18n.language,
        }),
      });

      clearInterval(progressInterval);

      if (!response.ok) {
        const errorData = await response.json();
        const { message, link } = errorData.error;

        setErrorMessage({
          message: message,
          link: link ? (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              {t("titleHeader.errors.contactSupport")}
            </a>
          ) : null,
        });
        return;
      }
      const data = await response.json();
      setSummary(data.summary);
      setProgress(100);
      setSummaryData({
        originalText,
        desiredLength,
        requiredPhrases,
        summary: data.summary,
      });
    } catch (error: any) {
      setErrorMessage({
        message: error.messsage || t("summary.messages.summaryGenerationError"),
        link: null,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhraseChange = (index: number, value: string) => {
    const newPhrases = [...requiredPhrases];
    newPhrases[index] = value;
    setRequiredPhrases(newPhrases);
    setSummaryData({
      originalText,
      desiredLength,
      requiredPhrases: newPhrases,
      summary,
    });
  };

  const addPhrase = () => {
    const newPhrases = [...requiredPhrases, ""];
    setRequiredPhrases(newPhrases);
    setSummaryData({
      originalText,
      desiredLength,
      requiredPhrases: newPhrases,
      summary,
    });
  };

  const removePhrase = (index: number) => {
    const newPhrases = requiredPhrases.filter((_, i: number) => i !== index);
    setRequiredPhrases(newPhrases);
    setSummaryData({
      originalText,
      desiredLength,
      requiredPhrases: newPhrases,
      summary,
    });
  };

  const copyToClipboard = () => {
    if (summaryRef.current) {
      setIsCopying(true);
      const textToCopy = summaryRef.current.value;
      navigator.clipboard.writeText(textToCopy).then(() => {
        alert(t("summary.messages.copiedToClipboard"));
        setIsCopying(false);
      });
    }
  };

  return (
    <div className="container mx-auto mt-5 px-4">
      {isLoading && (
        <div className="mt-4">
          <Progress value={progress} />
          <span className="text-sm text-gray-700">{progress}%</span>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="md:col-span-1">
          <div className="mb-4">
            <h5 className="block text-lg font-semibold mb-2">
              {t("summary.labels.originalText")}
            </h5>
            <textarea
              value={originalText}
              onChange={(e) => setOriginalText(e.target.value)}
              rows={10}
              className="w-full p-2 border rounded"
              placeholder={t("summary.labels.originalTextPlaceholder")}
              required
            />
            <div className="text-right mt-1 text-sm text-gray-600">
              {originalText.length} {t("summary.labels.characters")}
            </div>
          </div>

          <div className="mb-4">
            <label className="block font-medium mb-2">
              {t("summary.labels.desiredLength")}
            </label>
            <small className="text-gray-500">
              {t("summary.labels.lengthNote")}
            </small>
            <input
              type="number"
              value={desiredLength}
              onChange={(e) => setDesiredLength(Number(e.target.value))}
              min={1000}
              max={10000}
              className="w-full p-2 border rounded"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block font-medium mb-2">
              {t("summary.labels.requiredPhrases")}
            </label>
            <small className="text-gray-500">
              {t("summary.labels.requiredPhrasesNote")}
            </small>
            {requiredPhrases.map((phrase: string, index: number) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  value={phrase}
                  onChange={(e) => handlePhraseChange(index, e.target.value)}
                  className="flex-1 p-2 border rounded"
                  placeholder={`${t("summary.labels.phrase")} ${index + 1}`}
                  required
                />
                {requiredPhrases.length > 2 && (
                  <button
                    type="button"
                    onClick={() => removePhrase(index)}
                    className="ml-2 text-red-500"
                  >
                    {t("summary.labels.remove")}
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={addPhrase}
              className="mt-2 text-blue-500"
            >
              {t("summary.labels.addPhrase")}
            </button>
          </div>

          <Button variant="primary" disabled={isLoading} onClick={handleSubmit}>
            {isLoading ? (
              <>
                <span
                  className="animate-spin inline-block h-4 w-4 mr-2 border-2 border-white border-t-transparent rounded-full"
                  role="status"
                  aria-hidden="true"
                ></span>
                {t("summary.status.generating")}
              </>
            ) : (
              t("summary.actions.generateSummary")
            )}
          </Button>

          {errorMessage && (
            <div className="mt-4">
              <Alert variant="danger">
                {errorMessage.message} {errorMessage.link}
              </Alert>
            </div>
          )}
        </div>

        <div className="md:col-span-1">
          <div className="flex items-center justify-between mb-2">
            <h5 className="text-lg font-semibold">
              {t("summary.result.result")}
            </h5>

            <Button
              variant={summary ? "primary" : "ghost"}
              onClick={copyToClipboard}
              disabled={isCopying || !summary}
              aria-label={t("summary.actions.copyOutput")}
            >
              {t("summary.actions.copySummary")}
            </Button>
          </div>

          <textarea
            ref={summaryRef}
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
            rows={20}
            className="w-full p-2 border rounded resize-none"
            placeholder={t("summary.result.resultPlaceholder")}
          />

          <div className="text-right mt-1 text-sm text-gray-600">
            {summary.length} {t("summary.labels.characters")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
