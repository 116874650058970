// src/components/SubscriptionForm.tsx

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { getAuth } from "firebase/auth";
import log from "loglevel"; // loglevelのインポート
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "./ui/alert";
import { Button } from "./ui/button";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL!;

// ログレベルの設定
if (process.env.NODE_ENV === "production") {
  log.setLevel("error"); // 本番環境ではエラーログのみ
} else {
  log.setLevel("debug"); // 開発環境では全てのログを表示
}

interface UsageData {
  totalMinutesUsed: number;
  maxMinutes: number;
  titlesAndHeadingsUsage: number;
  maxTitlesAndHeadingsUsage: number;
  summarizeUsage: number;
  maxSummaryUsage: number;
  resetDate: string | null;
  plan: string;
  pendingPlan: string | null;
}

interface Plan {
  name: string;
  price: number;
}

interface SubscriptionFormProps {
  fetchUsageData: (uid: string) => Promise<void>;
  usageData: UsageData;
}

const SubscriptionForm: React.FC<SubscriptionFormProps> = ({
  fetchUsageData,
  usageData = {
    totalMinutesUsed: 0,
    maxMinutes: 30,
    titlesAndHeadingsUsage: 0,
    maxTitlesAndHeadingsUsage: 3,
    resetDate: null,
    plan: "free",
    pendingPlan: null,
    summarizeUsage: 0,
    maxSummaryUsage: 3,
  },
}) => {
  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState<string>("free");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [priceDifference, setPriceDifference] = useState<number | null>(null);
  const [nextBillingDate, setNextBillingDate] = useState<string | null>(null);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [currency, setCurrency] = useState<string>("USD");

  const stripe = useStripe();
  const elements = useElements();

  // バックエンドからプラン情報を取得する関数を useCallback でメモ化
  const fetchPlans = useCallback(
    async (uid: string) => {
      try {
        const idToken = await user.getIdToken(true);
        const response = await axios.get(
          `${BACKEND_URL}/api/subscriptions/plans`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setPlans(response.data.plans);
        setCurrency(response.data.currency);
        log.debug("取得したプラン情報:", response.data.plans);
      } catch (err: any) {
        log.error("プラン情報の取得に失敗しました:", err);
      }
    },
    [user]
  );

  // ユーザーの認証情報を取得
  useEffect(() => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUser(currentUser);
      fetchUsageData(currentUser.uid);
      log.debug("Authenticated user:", currentUser);
      fetchPlans(currentUser.uid);
    }
  }, [fetchUsageData, fetchPlans]);

  /**
   * プラン変更時に価格差を計算する
   */
  useEffect(() => {
    if (user?.plan && plans.length > 0) {
      const currentPlan = plans.find((plan) => plan.name === user.plan);
      const newPlan = plans.find((plan) => plan.name === selectedPlan);
      if (currentPlan && newPlan) {
        if (selectedPlan === "standard" && user.plan === "premium") {
          setPriceDifference(currentPlan.price - newPlan.price);
        } else if (selectedPlan === "premium" && user.plan === "standard") {
          setPriceDifference(newPlan.price - currentPlan.price);
        } else {
          setPriceDifference(null);
        }
      } else {
        setPriceDifference(null);
      }
    } else {
      setPriceDifference(null);
    }
  }, [selectedPlan, user, plans]);

  /**
   * フォーム送信時の処理
   * @param {React.FormEvent} event - フォームイベント
   */
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);
    setSuccess(null);

    if (!stripe || !elements) {
      log.error(t("subscription.error.stripe_elements_not_loaded"));
      return;
    }

    if (!user) {
      setError(t("subscription.error.user_not_logged_in"));
      log.warn(t("subscription.warn.no_authenticated_user")); // 警告をログに出力
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // 無料プランの場合、カード情報は不要
    if (selectedPlan !== "free" && !cardElement) {
      setError(t("subscription.error.card_info_not_retrieved"));
      return;
    }

    setLoading(true);

    try {
      let paymentMethodId = null;

      // 有料プランの場合のみ支払い方法を作成
      if (selectedPlan !== "free") {
        const { error: paymentMethodError, paymentMethod } =
          await stripe.createPaymentMethod({
            type: "card",
            card: cardElement!,
          });

        if (paymentMethodError) {
          setError(
            paymentMethodError.message ||
              t("subscription.error.payment_method_creation_failed")
          );
          log.error(
            t("subscription.error.payment_method_error"),
            paymentMethodError
          ); // エラーをログに出力
          setLoading(false);
          return;
        }

        paymentMethodId = paymentMethod?.id;
      }

      // Firebase Auth から ID トークンを取得
      const idToken = await user.getIdToken(true);

      // フォーム送信時に選択されたプラン名をバックエンドに送信
      const response = await axios.post(
        `${BACKEND_URL}/api/subscriptions/create-or-update-subscription`,
        {
          paymentMethodId,
          selectedPlan, // priceIdではなくselectedPlanを送信
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      // 次回請求日を設定
      if (response.data.nextBillingDate) {
        setNextBillingDate(response.data.nextBillingDate);
        log.debug(
          t("subscription.debug.next_billing_date"),
          response.data.nextBillingDate
        ); // 次回請求日を出力
      }

      // クライアントシークレットがある場合のみ支払いを確認
      if (response.data.clientSecret) {
        const { clientSecret } = response.data;

        // Stripeの支払い確認
        const result = await stripe.confirmCardPayment(clientSecret);

        if (result.error) {
          setError(
            result.error.message || t("subscription.error.payment_failed")
          );
          log.error(
            t("subscription.error.payment_confirmation_error"),
            result.error
          ); // エラーをログに出力
        } else if (result.paymentIntent?.status === "succeeded") {
          setSuccess(t("subscription.success.processed"));
          log.info(t("subscription.info.subscription_success")); // 成功メッセージをログに出力
          fetchUsageData(user.uid);
        } else if (result.paymentIntent?.status === "requires_action") {
          setError(t("subscription.error.additional_auth_required"));
          log.warn(t("subscription.warn.requires_additional_action")); // 警告をログに出力
        }
      } else if (response.data.message) {
        setSuccess(response.data.message);
        fetchUsageData(user.uid);
        log.info(
          t("subscription.info.subscription_message"),
          response.data.message
        ); // メッセージをログに出力
      } else {
        throw new Error(t("subscription.error.client_secret_not_retrieved"));
      }
    } catch (err: any) {
      log.error(t("subscription.error.generic"), err); // エラーをログに出力
      setError(err.response?.data?.error || t("subscription.error.unexpected"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center mt-8 px-4">
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-center mb-6">
          {t("subscription.title")}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* プラン選択 */}
          <div>
            <label
              htmlFor="planSelect"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              {t("subscription.select_plan")}
            </label>
            <select
              id="planSelect"
              value={selectedPlan}
              onChange={(e) => setSelectedPlan(e.target.value)}
              disabled={loading}
              className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              aria-label={t("subscription.select_plan")}
            >
              {plans.map((plan) => (
                <option key={plan.name} value={plan.name}>
                  {t(`subscription.${plan.name}_plan`)}
                </option>
              ))}
            </select>
          </div>

          {/* 価格差の表示 */}
          {priceDifference !== null && (
            <p className="text-sm text-gray-600 mt-2">
              {t("subscription.price_difference")}:{" "}
              {currency === "JPY" ? "¥" : "$"}
              {priceDifference}
            </p>
          )}

          {/* カード情報の入力（有料プランのみ） */}
          {selectedPlan !== "free" && (
            <div>
              <label
                htmlFor="cardElement"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                {t("subscription.card_info")}
              </label>
              <div className="p-3 border border-gray-300 rounded-md">
                <CardElement
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        color: "#000",
                        fontSize: "16px",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                        backgroundColor: "#fff",
                        padding: "10px",
                      },
                      invalid: {
                        color: "#fa755a",
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}

          {/* 送信ボタン */}
          <Button
            type="submit"
            variant="primary"
            className="w-full flex items-center justify-center"
            disabled={!stripe || loading || user?.plan === selectedPlan}
          >
            {loading ? (
              <>
                <span className="ml-2">{t("subscription.processing")}</span>
              </>
            ) : selectedPlan === "free" ? (
              t("subscription.switch_to_free")
            ) : (
              t("subscription.change_plan")
            )}
          </Button>

          {/* プラン変更に関する注意事項 */}
          {selectedPlan !== "free" && (
            <p className="text-sm text-gray-600 mt-2">
              {t("subscription.upgrade_note")}
            </p>
          )}

          {/* 次回請求日 */}
          {nextBillingDate && (
            <p className="text-sm text-gray-600 mt-2">
              {t("subscription.auto_renewal", { date: nextBillingDate })}
            </p>
          )}
        </form>

        {/* エラーメッセージ */}
        {error && <Alert variant="danger">{error}</Alert>}

        {/* 成功メッセージ */}
        {success && <Alert variant="success">{success}</Alert>}

        {/* 現在のプラン状況と使用状況 */}
        <div className="mt-12">
          <h5 className="text-lg font-semibold">
            {t("subscription.current_plan_status", {
              plan:
                usageData.plan === "free"
                  ? t("subscription.free_plan")
                  : usageData.plan === "standard"
                  ? t("subscription.standard_plan")
                  : t("subscription.premium_plan"),
            })}
          </h5>
          {/* トランスクリプション使用状況 */}
          <div className="mt-2">
            <span className="whitespace-nowrap">
              {t("subscription.transcription_usage")}:{" "}
              {usageData.totalMinutesUsed} / {usageData.maxMinutes}{" "}
              {t("subscription.units.minutes")}
            </span>
            <div className="w-full bg-gray-200 rounded-full h-4 mt-1">
              <div
                className={`h-4 rounded-full ${
                  (usageData.totalMinutesUsed / usageData.maxMinutes) * 100 > 80
                    ? "bg-red-500"
                    : "bg-green-500"
                }`}
                style={{
                  width: `${Math.min(
                    (usageData.totalMinutesUsed / usageData.maxMinutes) * 100,
                    100
                  )}%`,
                }}
              ></div>
            </div>
            <span className="text-sm text-gray-700">
              {Math.min(
                (usageData.totalMinutesUsed / usageData.maxMinutes) * 100,
                100
              ).toFixed(0)}
              %
            </span>
          </div>

          {/* サマリー使用状況 */}
          <div className="mt-2">
            <span className="whitespace-nowrap">
              {t("subscription.summarize_usage")}: {usageData.summarizeUsage} /{" "}
              {usageData.maxSummaryUsage} {t("subscription.units.times")}
            </span>
            <div className="w-full bg-gray-200 rounded-full h-4 mt-1">
              <div
                className={`h-4 rounded-full ${
                  (usageData.summarizeUsage / usageData.maxSummaryUsage) * 100 >
                  80
                    ? "bg-red-500"
                    : "bg-green-500"
                }`}
                style={{
                  width: `${Math.min(
                    (usageData.summarizeUsage / usageData.maxSummaryUsage) *
                      100,
                    100
                  )}%`,
                }}
              ></div>
            </div>
            <span className="text-sm text-gray-700">
              {Math.min(
                (usageData.summarizeUsage / usageData.maxSummaryUsage) * 100,
                100
              ).toFixed(0)}
              %
            </span>
          </div>

          {/* タイトルと見出しの使用状況 */}
          <div className="mt-2">
            <span className="whitespace-nowrap">
              {t("subscription.titles_and_headings_usage")}:{" "}
              {usageData.titlesAndHeadingsUsage} /{" "}
              {usageData.maxTitlesAndHeadingsUsage}{" "}
              {t("subscription.units.times")}
            </span>
            <div className="w-full bg-gray-200 rounded-full h-4 mt-1">
              <div
                className={`h-4 rounded-full ${
                  (usageData.titlesAndHeadingsUsage /
                    usageData.maxTitlesAndHeadingsUsage) *
                    100 >
                  80
                    ? "bg-red-500"
                    : "bg-green-500"
                }`}
                style={{
                  width: `${Math.min(
                    (usageData.titlesAndHeadingsUsage /
                      usageData.maxTitlesAndHeadingsUsage) *
                      100,
                    100
                  )}%`,
                }}
              ></div>
            </div>
            <span className="text-sm text-gray-700">
              {Math.min(
                (usageData.titlesAndHeadingsUsage /
                  usageData.maxTitlesAndHeadingsUsage) *
                  100,
                100
              ).toFixed(0)}
              %
            </span>
          </div>
        </div>

        {/* 次回リセット日とペンディングプランの表示 */}
        {usageData.plan !== "free" && usageData.resetDate && (
          <small className="block text-xs text-gray-500 mb-2 mt-4">
            {t("subscription.next_billing_and_reset", {
              date: usageData.resetDate,
            })}
          </small>
        )}

        {usageData.pendingPlan != null && usageData.resetDate && (
          <div className="mt-4 p-2 bg-yellow-100 text-yellow-800 text-xs rounded">
            <p>
              <strong>{usageData.resetDate}</strong>{" "}
              {t("subscription.pending_plan_switch", {
                plan:
                  usageData.pendingPlan === "free"
                    ? t("subscription.free_plan")
                    : usageData.pendingPlan === "standard"
                    ? t("subscription.standard_plan")
                    : t("subscription.premium_plan"),
              })}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionForm;
